import { css, cx } from '@emotion/css'
import { AliasToken } from 'antd/es/theme/internal'

const renderStyles = (args: { token: Partial<AliasToken>; dark: boolean }) => {
  const { token, dark } = args
  return {
    sider: cx(
      'h-full',
      css({
        boxShadow: '0px 1px 4px 0px rgba(0, 21, 41, 0.12)',
        background: `transparent !important`,
        '.ant-layout-sider-children': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 0
        }
      })
    ),
    siderLogo: cx(
      'w-full flex items-center overflow-hidden',
      css({ height: 90, margin: '0 35px' })
    ),
    siderLogoIn: cx(
      css({
        display: 'flex', alignItems: 'center', justifyContent: 'center'
      })
    ),
    siderContent: cx('flex flex-col item-center justify-center', css({ marginLeft: 12 })),
    siderLogoText: css({
      fontSize: '24px',
      color: 'rgba(51, 51, 51, 1)',
      lineHeight: '33px',
      fontWeight: 600
    }),
    company: css({
      fontSize: 10,
      color: 'rgba(22, 119, 255, 1)'
    }),
    siderIcon: css({ width: '48px', height: '48px' }),

    menu: cx(
      'flex-1 overflow-y-auto',
      css({
        '.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.css-y669ws.css-dev-only-do-not-override-15qkjwx': {
          backgroundColor: 'transparent'
        },
        '.ant-menu-light .ant-menu-item-selected': {
          color: 'black !important',
        },
        '.ant-menu-dark .ant-menu-item-selected': {
          color: 'rgb(255, 255, 255)'
        },
        '.ant-menu-dark .ant-menu-submenu-title, .ant-menu-dark ant-menu-title-content': {
          color: 'black',
          paddingLeft: '16px !important',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '40px',
          height: '40px',
        },
        '.ant-menu-title-content': {
        },
        '.ant-menu-sub.ant-menu-inline': {
          padding: '0 24px !important',
          color: 'black !important',
          backgroundColor: 'transparent!important',
        },
        'ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child': {
          color: 'black',
        },
        '.ant-menu-submenu-selected > .ant-menu-submenu-title': {
          color: 'black',
          '> .ant-menu-title-content,> .ant-menu-inline-collapsed-noicon': {
            fontWeight: 500
          }
        },
        '.ant-menu-item-selected': {
          '.ant-menu-title-content,.ant-menu-inline-collapsed-noicon': {
            fontWeight: 500
          }
        },
        '.ant-menu-item': {
          paddingLeft: '16px !important',
          color: 'rgb(89, 89, 89)'
        },
        '.ant-menu-item-active:not(.ant-menu-item-selected)': {
          color: 'black !important'
        }
      })
    )
  }
}

export default renderStyles
