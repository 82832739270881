/*
 * @Date: 2023-08-28 17:09:17
 * @Description: 接口请求
 */

import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
// 反向引用
// @ts-ignore
import { API_PREFIX, BUSINESS } from '@/core/config'
// @ts-ignore
import * as coreConfig from '@/core/config'
// @ts-ignore
import { dispatch, actions } from '@/store'
import { IModels } from '../../yapi'
import { filterEmpty } from '../tools/object'
import { message, notification } from 'antd'
import omit from 'lodash/omit'

type Config = {
  url: string
  method: string
  data: Record<string, any>
  options: {
    /** 是否显示loading */
    showMessage?: boolean
    axiosExtraOption?: AxiosRequestConfig<any>
    headers?: any // TODO
    /** 是否删除值为 null、undefined、'' 的字段 */
    filterEmptyData?: boolean
    baseUrl?: string
    /** 是否在并发请求忽略key值 主要用于请求竟态 */
    ignoreFetchKey?: string
  }
}


interface DataRes<T> {
  code: number
  message: string
  data: T
  askId: string
  error: boolean
}

type Response = IModels & { [key: string]: any }

declare type Fetch = {
  [K in keyof Response]: (
    req?: K extends keyof IModels ? Req<K> : any,
    extra?: Config['options'],
  ) => Promise<
    [K extends keyof IModels ? Res<K> : any, DataRes<K extends keyof IModels ? Res<K> : any>]
  >
}

const promiseMap = {}
const controllerMap: Record<string, CancelTokenSource> = {}
const fetchData = (options: Config) => {
  const { ignoreFetchKey = '' } = options?.options || {}
  const key = JSON.stringify({ ...options, data: omit(options?.data || {}, ignoreFetchKey) })
  if (promiseMap[key] && controllerMap[key]) {
    controllerMap[key].cancel()
  }
  promiseMap[key] = ajax(options)
  return promiseMap[key]
}

const memoMessage = new Set<string>()
const debounceError = (message: string) => {
  if (!message || memoMessage.has(message)) {
    return
  }
  if (message?.length < 30) {
    $.msg.error(message)
  } else {
    notification.error({
      message: '发生错误',
      description: message,
    })
  }
  memoMessage.add(message)
  setTimeout(() => {
    memoMessage.delete(message)
  }, 500)
}

function ajax<T>(options: {
  url: any
  method: any
  data: any
  options: Config['options']
}): Promise<T> {
  const {
    url,
    method,
    data,
    options: {
      showMessage = true,
      headers = {},
      axiosExtraOption,
      filterEmptyData,
      baseUrl,
      ignoreFetchKey = ''
    },
  } = options
  const reqData = filterEmptyData ? filterEmpty(data) : data
  // 是否为校园服务
  const isCampus = url.includes('/at/')
  const controller = axios.CancelToken.source()
  const key = JSON.stringify({ ...options, data: omit(options?.data || {}, ignoreFetchKey) })
  controllerMap[key] = controller
  return axios({
    baseURL: baseUrl || (isCampus ? coreConfig?.API_CCOS_PREFIX : API_PREFIX),
    url,
    method,
    params: method === 'get' ? reqData : undefined,
    data: method === 'post' ? reqData : undefined,
    withCredentials: true,
    headers: {
      business: BUSINESS,
      ...headers,
    },
    ...axiosExtraOption,
    cancelToken: controller.token,
  })
    .then(({ status, data }) => {
      if (status === 200) {
        // if (import.meta.env.DEV && isMock) {
        //   const resData = data.code == null && data.message == null ? { data, code: 0 } : data
        //   return [data, resData]
        // }
        if (data.code === 0) {
          if (showMessage && data.message && !['SUCCESS', '请求成功','成功'].includes(data.message)) {
            $.msg.success(data.message)
          }
          return [data.data, data]
        }
        // 错误toast提示
        if (showMessage && data.message && data.code !== 100001 && data.code !== 601 && data.code !== 31010001) {
          debounceError(data.message)
        }
        // 已经退出登录
        if (data.code === 601) {
          // 清除token
          debounceError('登录失效')
          dispatch(actions.global.setState({ tokenFailure: true }))
        }
        // 权益价值冲突 code: 31010001不reject
        if (data.code === 31010001) {
          return [data]
        }
        return Promise.reject(data) as any
      }

      throw Error('请求错误')
    })
    .catch((result) => {
      if(axios.isCancel(result)){
        return promiseMap[key]
      }
      // 接口异常，状态码不是200
      if (result.name === 'AxiosError') {
        const { message: msg, code, } = result.response.data
        if (showMessage && code === 601) {
          debounceError('登录失效')
        } else if (showMessage && msg) {
          message.error(msg)
        }
        return Promise.reject(result.response.data)
      }
      return Promise.reject(result)
    }).finally(()=>{
      promiseMap[key] = null
    }) as Promise<T>
}

const request = new Proxy(
  {},
  {
    get(_, p) {
      return (data: any = {}, options = {}) => {
        const [method, ...urlArr] = (p as string).split('/')
        // 如果是开发环境，统一加一个 /api 前缀，用于代理到后端地址
        const url = `/${urlArr.join('/')}`
        return fetchData({
          url,
          method: method.toLowerCase(),
          data,
          options,
        })
      }
    },
  },
) as Fetch

export default request
